import image1 from "../../assets/t-image1.png";
import image2 from "../../assets/t-image2.jpg";
import image3 from "../../assets/t-image3.jpg";

export const TestimonialsData = [
  {
    name: "MATHEW HENDRICKSON",
    status: " ENTREPRENEUR",
    image: image1,
    text: "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
  },
  {
    name: "JOHN KEVIN",
    status: " COACH",
    image: image2,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint",
  },
  {
    name: "FRANKLIN ",
    status: " Customer",
    image: image3,
    text: "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
  },
];
